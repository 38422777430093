<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">客户信息</div>
            </div>
            <template>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" @row-update="handleUpdate" @row-del="del(row, index, done)" @row-save="handleSave">
                    <template slot="menuLeft" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>编码:</span>
                                    <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                            
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span id="CustormSpan">客户名称:</span>
                                    <el-input v-model="CustormVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span id="allSpan">客户分组:</span>
                                    <el-select v-model="groupVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="allchang" clearable>
                                        <el-option v-for="item in this.vendorvectionData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 25px; text-align: right; align-self: flex-end;">
                                <el-button :title="$t('Search')" icon="iconfont icon-textile-sousuo" size="mini" type="primary" @click="searchFrom">  {{$t('Search')}}</el-button>
                                <el-button type="primary" icon="iconfont icon-textile-tianjia" size="mini" :title="$t('New')" plain @click="$refs.crud.rowAdd()"> {{$t('New')}}</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu" >
                        <el-button  type="text" size="medium" :title="$t('Edit')" @click="$refs.crud.rowEdit(row,index)"><span style="font-size:14px">{{$t('Edit')}}</span></el-button>
                        <el-button type="text" size="medium" :title="$t('Edit')" @click="$router.push({ path: '/custorm/custormRelation', query: { id: row.id,custormName:row.custormName } })"><span style="font-size:14px">关联</span></el-button>
                        <el-button type="text" size="medium" :title="$t('Delete')" @click="del(row,index)"><span style="font-size:14px">{{$t('Delete')}}</span></el-button>
                        <!--<el-button icon="iconfont icon-vip353" :class="row.status===3?'primary':'no'" type="text" size="medium" :title="$t('Delete')" @click="tracingPur(row,index,size,type)"></el-button>-->
                    </template>
                    <template slot-scope="{row,index,type}" slot="menuForm">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                        <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                        <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style>
</style>
<script>
    import { listInfo, delInfo, editInfo, addInfo, settlementMethodDDL, getCreditLimitById, codeIsExist} from "@/api/custorm/custormInfo";
    import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool} from "@/api/unified";

    export default {
        data() {
            return {
                obj: {},
                codeVal: "",
                nameVal: "",
                CustormVal: "",
                groupVal: {
                    ids: [],
                },
                locationVal: "",
                tableData: [],
                vendorData: [],
                vendorAddData: [],
                vendorvectionData: [],//客户类别
                settlementMethodData: [],
                creditLimitData: [],
                editBtnText: this.$t('Edit'),
                colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                }
               
            }
        },
        computed: {
            setData() {
                const validateMobile = (rule, value, callback) => {
                    if (value != '' && value != null) {
                    if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                        callback(new Error("请输⼊正确的⼿机号格式"));
                    } else {
                        callback();
                        }
                    } else {
                        callback();
                    }
                }
                const ValidateAmount = (rule, value, callback) => {
                    if (value != '' && value != null) {
                        if (!/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(value)) {
                            callback('请输入数字，金额输入不能小于0，最多只可保留2位小数')
                        } else {
                            callback()
                        }
                    } else {
                        callback()
                    }
                }
                var validateCode = (rule, value, callback) => {
                    if (value) {
                        if (/[\u4E00-\u9FA5]/g.test(value)) {
                            callback(new Error('不能包含中文!'))
                        } else {
                            callback()
                        }
                    }
                };
                var validateCodeExist = (rule, value, callback) => {
                    if ((value != '' || value != null) && (this.obj.id == '' || this.obj.id == null)) {
                        codeIsExist(value).then(resu => {
                            var IsExist = resu.data;
                            if (IsExist) {
                                callback(new Error(this.$t('CodeIsExist')));
                            } else { callback(); }
                        }).catch(() => { callback(); });
                    } else {
                        callback();
                        }
                };
              
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        header: true,
                       /* labelPosition:'top',*/
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        column: [
                            {
                                label:"编码",// this.$t('CertifiedCode'),
                                prop: 'code',
                                editDisabled: true,//禁止编辑
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" },
                                    { required: true, validator: validateCode, trigger: "blur" },
                                    { required: true, validator: validateCodeExist, trigger: "blur" }
                                      
                                ]
                            },
                            {
                                label: "客户",// this.$t('Custorm'),
                                prop: 'custormName',
                                placeholder: ' ',
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "客户分组",
                                prop: 'custormGroup',
                                type: "select",
                                filterable: true,
                                hide: true,
                                value: 'A', // 默认选A
                                dicData: [{
                                    label: 'groupA',
                                    value: 'A'
                                }, {
                                    label: 'groupB',
                                    value: 'B'
                                }, {
                                    label: 'groupC',
                                    value: 'C'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "手机号",
                                prop: 'mobileNo',
                                filterable: true,
                               
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" },
                                    { required: false, validator: validateMobile, trigger: "blur" }]
                            }
                            ,
                            {
                                label: "客户类型",
                                prop: 'custormType',
                                type: "select",
                                filterable: true,
                                hide: true,
                                value: 'A', // 默认选A
                                dicData: [{
                                    label: 'typeA',
                                    value: 'A'
                                }, {
                                    label: 'typeB',
                                    value: 'B'
                                }, {
                                    label: 'typeC',
                                    value: 'C'
                                    }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }
                            ,
                            {
                                label: "联系人",
                                prop: 'custormContact',
                                filterable: true,
                               
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label:"结算方式",
                                prop: 'settlementMethodId',
                                type: "select",
                                dicData: this.settlementMethodData,
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]                                  
                            },
                            {
                                label: "默认货币",
                                prop: 'defaultCurrency',
                                type: "select",
                                filterable: true,
                                hide: true,
                                //dicData: this.vendorAddData,
                                value: 'A', // 默认选A
                                dicData: [{
                                    label: '人民币',
                                    value: 'A'
                                }, {
                                    label: '美元',
                                    value: 'B'
                                }, {
                                    label: '欧元',
                                    value: 'C'
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "信用额度",
                                prop: 'creditLimit',
                                filterable: true,
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" },
                                    { required: false, validator: ValidateAmount, trigger: "blur" }]
                            },
                            {
                                label: "额度单位",
                                prop: 'creditLimitUnit',
                                filterable: true,
                                placeholder: '',
                                type: "select",
                                value: 'C',
                                dicData: [{
                                    label: '千',
                                    value: 'A'
                                }, {
                                    label: '万',
                                    value: 'B'
                                }, {
                                    label: '百万',
                                    value: 'C'
                                    },{
                                        label: '千万',
                                        value: 'D'
                                    }],
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "语言",
                                prop: 'language',
                                type: "select",
                                filterable: true,
                                hide: true,
                               /* dicData: this.vendorAddData,*/
                                value: 'A', // 默认选A
                                dicData: [{
                                    label: '中文',
                                    value: 'A'
                                }, {
                                    label: '英文',
                                    value: 'B'
                                }, {
                                    label: '法文',
                                    value: 'C'
                                    }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                         
                            {
                                label: "地址",
                                prop: 'primaryAddressId',
                                type: "select",
                                filterable: true,
                                hide: true,
                                dicData: this.vendorAddData,
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }
                        ]
                    }
                }
            }
        },
        created() {
            this.getList();
            this.init()
        },
        methods: {
            init() {
                
                settlementMethodDDL(1).then(res => {
                    this.settlementMethodData = res.data;
                });

                TracingDropDownList('VendorAddress').then(res => {
                    this.vendorAddData = res.data;
                });
            },
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.CustormVal.ids.length === 0) {
                        document.querySelector("#CustormSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                    if (this.groupVal.ids.length === 0) {
                        document.querySelector("#allSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }
            },
            Custormchang() {
                if (this.CustormVal.ids.length === 0) {
                    document.querySelector("#CustormSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#CustormSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//
            allchang() {
                if (this.groupVal.ids.length === 0) {
                    document.querySelector("#allSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#allSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//
            rowStyle({rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val;
                this.getList();
            },//搜索
            searchFrom() {
                this.getList();
            },//编辑数据
            handleUpdate(row, index, done) {
       
                editInfo(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
                    this.getList();
                    this.obj = {};
                })
                done();
            },
            //},//删除数据
            del(row, index, done) {
                this.$confirm(
                    this.$t('IsDel'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delInfo(row.id).then(() => {
                        this.getList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                        done();
                    })
                })
            },
            //显示数据    
            async getList() {
                await listInfo(this.codeVal,  this.CustormVal.toString(), this.groupVal.ids.toString(),  this.page.currentPage, this.page.pageSize).then(res => {
                    var cardata = res.data.custormInfos;
                    this.tableData = cardata;
                    this.page.total = res.data.totalCount;
                    this.page.pageSize = res.data.pageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            //认证状态
            tracingPur(row) {
                this.$message({ showClose: true, message: this.$t('online'), type: "warning" });
               // editStatus(row);
            },
            //新增数据 
            handleSave(row, done) {
                addInfo(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
                    this.getList();
                    this.obj = {};
                    done();
                }).catch(() => { });
            },
        }
    }
</script>